
<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <div class="banner_search_block_inner">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-6">
                        <div class="search_block">
                            <h1>Hi, How can we help?</h1>
                            <div class="search"> <i class="fa fa-search"></i> <input type="text" class="form-control" placeholder="Enter your search keywords..." name="search" #search="ngModel" [(ngModel)]="searchEntity.search"> 
                              <button class="theme_btn all_btn" [disabled]="((searchEntity.search && searchEntity.search.length>=3) ? false : true)" (click)="queAnsSearch()">Search</button> </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-6 col-xxl-6">
                        <img src="../assets/images/faqs.png" alt="" class="img-fluid faq_img" />
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
<div class="faq_tab_block search_block">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          
          <div class="tab_block">
            <div *ngIf="statement_show" >
            <h5>We found <span>{{(searchGeneralQueAnsArray.count + searchAadministratorsQueAnsArray.count + searchSystemRequirementsQueAnsArray.count)}} questions</span> based on your search. 
              <button class="clear_search_btn" (click)="clearSearch()"><i class="fa fa-times" aria-hidden="true"></i>Clear search</button>
            </h5>
          </div>
          <ul class="nav nav-tabs main_tab_block float-start" id="mainTab" role="tablist">
            <li class="nav-item" role="presentation" id="general">
              <button class="nav-link active" id="Assessments-tab" data-bs-toggle="tab" data-bs-target="#Assessments" type="button" role="tab" aria-controls="Assessments" aria-selected="false">
                <img src="../assets/images/da.png" alt="" class="img-fluid" />
                <span class="content"><h2>General <span *ngIf="statement_show">({{searchGeneralQueAnsArray.count}}) </span></h2></span></button>
            </li>
            <li class="nav-item" role="presentation" id="administrator">
              <button class="nav-link" id="Administrators-tab" data-bs-toggle="tab" data-bs-target="#Administrators" type="button" role="tab" aria-controls="Administrators" aria-selected="false">
                <img src="../assets/images/ua.png" alt="" class="img-fluid" />
                <span class="content"><h2>For Administrators <span *ngIf="statement_show">({{searchAadministratorsQueAnsArray.count}}) </span></h2></span></button>
            </li>
            <li class="nav-item system_link" role="presentation" id="systemRequirement">
              <button class="nav-link" id="System-tab" data-bs-toggle="tab" data-bs-target="#System" type="button" role="tab" aria-controls="System" aria-selected="false">System Requirements <span *ngIf="statement_show">({{searchSystemRequirementsQueAnsArray.count}})</span></button>
            </li>
          </ul>
          <!-- <a id="System-tab" data-bs-toggle="tab" data-bs-target="#System" class="float-end system_link mt-3" role="tab" aria-controls="System" aria-selected="false">System Requirements</a> -->
          <div class="clearfix"></div>
          <div class="tab-content" id="mainTabContent">
            <div class="tab-pane fade" id="System" role="tabpanel" aria-labelledby="System-tab">
              <div class="faq_content">
              <button class="all_btn theme_btn float-end" id="showanswer_tab5" (click)="show_answers(5)">Show all answers</button>
              <button class="all_btn theme_btn float-end" id="hideanswer_tab5" (click)="hide_answers(5)" style="display: none;">Hide all answers</button>
              <div class="clearfix"></div>
              
              <div class="accordion" id="mainCat1Question1">
                <ng-container *ngFor="let systemRequirement of searchSystemRequirementsQueAnsArray;let c=index;">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="cat5quesheader{{c}}">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#cat5ques'+c" aria-expanded="true" [attr.aria-controls]="'cat5ques'+c">
                        {{systemRequirement.question}}
                      </button>
                    </h2>
                    <div id="cat5ques{{c}}" class="accordion-collapse collapse " [ngClass]="{show:c==0}" [attr.aria-labelledby]="'cat5quesheader'+c">
                      <div class="accordion-body">
                        <span [innerHTML]="systemRequirement.answer"></span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- <div class="accordion-item">
                  <h2 class="accordion-header" id="cat5ques1header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques1" aria-expanded="true" aria-controls="cat5ques1">
                      What are the system requirements to take Competency Framework?
                    </button>
                  </h2>
                  <div id="cat5ques1" class="accordion-collapse collapse show" aria-labelledby="cat5ques1header">
                    <div class="accordion-body">
                      This is the placeholder for answer
                    </div>
                  </div>
                </div> -->
                <!-- <div class="accordion-item">
                  <h2 class="accordion-header" id="cat5ques2header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques2" aria-expanded="false" aria-controls="cat5ques2">
                      Which web browser I can use to access Competency Framework?
                    </button>
                  </h2>
                  <div id="cat5ques2" class="accordion-collapse collapse" aria-labelledby="cat5ques2header">
                    <div class="accordion-body">
                        This is the placeholder for answer
                    </div>
                  </div>
                </div> -->
                <!-- <div class="accordion-item">
                  <h2 class="accordion-header" id="cat5ques3header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques3" aria-expanded="false" aria-controls="cat5ques3">
                      How to check if I am using the latest web browser?
                    </button>
                  </h2>
                  <div id="cat5ques3" class="accordion-collapse collapse" aria-labelledby="cat5ques3header">
                    <div class="accordion-body">
                        This is the placeholder for answer
                    </div>
                  </div>
                </div> -->
                <!-- <div class="accordion-item">
                    <h2 class="accordion-header" id="cat5ques4header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques4" aria-expanded="false" aria-controls="cat5ques4">
                        What if the website is not loading in the browser?
                      </button>
                    </h2>
                    <div id="cat5ques4" class="accordion-collapse collapse" aria-labelledby="cat5ques4header">
                      <div class="accordion-body">
                          This is the placeholder for answer
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="accordion-item">
                    <h2 class="accordion-header" id="cat5ques5header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques5" aria-expanded="false" aria-controls="cat5ques5">
                        How do I clear the browser Cache and Cookies?
                      </button>
                    </h2>
                    <div id="cat5ques5" class="accordion-collapse collapse" aria-labelledby="cat5ques5header">
                      <div class="accordion-body">
                          This is the placeholder for answer
                      </div>
                    </div>
                  </div> -->
              </div>
              </div>
            </div>
            <div class="tab-pane fade  show active" id="Assessments" role="tabpanel" aria-labelledby="Assessments-tab">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-2 pe-0 ps-0">
                    <div class="faq_tabs">
                      <ul class="nav nav-tabs" id="faqTab" role="tablist">
                        <ng-container *ngFor="let category of searchGeneralQueAnsArray;let i=index;">
                          <li class="nav-item" role="presentation" *ngIf="category.child.length > 0">
                            <button class="nav-link active" [ngClass]="{active:i==0}" id="general-tab{{i+1}}" data-bs-toggle="tab" [attr.data-bs-target]="'#general'+(i+1)" type="button" role="tab" [attr.aria-controls]="'general'+(i+1)" aria-selected="true">
                                <span class="content"><h2>{{category.subCategory}} <span *ngIf="statement_show">({{category.child.length}})</span></h2></span>
                                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                            </button>
                          </li>
                        </ng-container>
                        
                      </ul>
                        <!-- <ul class="nav nav-tabs" id="faqTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="general-tab1" data-bs-toggle="tab" data-bs-target="#general1" type="button" role="tab" aria-controls="general1" aria-selected="true">
                                    <span class="content"><h2>Getting Started</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="general-tab2" data-bs-toggle="tab" data-bs-target="#general2" type="button" role="tab" aria-controls="general2" aria-selected="false">
                                    <span class="content"><h2>All about License</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="general-tab3" data-bs-toggle="tab" data-bs-target="#general3" type="button" role="tab" aria-controls="general3" aria-selected="false">
                                    <span class="content"><h2>Personality Assessment</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="general-tab4" data-bs-toggle="tab" data-bs-target="#general4" type="button" role="tab" aria-controls="general4" aria-selected="false">
                                    <span class="content"><h2>Competency Assessment</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="general-tab5" data-bs-toggle="tab" data-bs-target="#general5" type="button" role="tab" aria-controls="general5" aria-selected="false">
                                    <span class="content"><h2>About Extension</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-10 ps-0 pe-0">
                    <div class="faq_content">
                        <div class="tab-content" id="faqTabContent">
                          <ng-container *ngFor="let generalquestions of searchGeneralQueAnsArray;let i = index;">
                            <div class="tab-pane fade " id="general{{i+1}}" role="tabpanel" [attr.aria-labelledby]="'general-tab'+(i+1)"
                              [ngClass]="{show:i==0,active:i==0}">
                              <div class="title_block float-start">
                                  <span class="content"><h2>{{generalquestions.subCategory}}</h2></span>
                              </div>
                              <button class="all_btn theme_btn float-end" id="showanswer_tab{{i+1}}" (click)="show_answers(i+1)">Show all answers</button>
                              <button class="all_btn theme_btn float-end" id="hideanswer_tab{{i+1}}" (click)="hide_answers(i+1)" style="display: none;">Hide all answers</button>
                              <div class="clearfix"></div>
                              <div class="accordion" id="question_tab{{i+1}}" >
                                  <div class="accordion-item" *ngFor="let queans of generalquestions.child;let j=index;">
                                    <h2 class="accordion-header" id="cat1quesheader{{i}}{{j}}">
                                      <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#cat1ques'+i+j" aria-expanded="true" [attr.aria-controls]="'cat1ques'+i+j">
                                        {{queans.question}}
                                      </button>
                                    </h2>
                                    <div id="cat1ques{{i}}{{j}}" class="accordion-collapse collapse " [ngClass]="{show:j==0}" [attr.aria-labelledby]="'cat1quesheader'+i+j">
                                      <div class="accordion-body">
                                        <span [innerHTML] = "queans.answer"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </ng-container>
                            <!-- <div class="tab-pane fade show active" id="general1" role="tabpanel" aria-labelledby="general-tab1">
                                <div class="title_block float-start">
                                    <span class="content"><h2>Getting Started</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab1" (click)="show_answers(1)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab1" (click)="hide_answers(1)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab1">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat1ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques1" aria-expanded="true" aria-controls="cat1ques1">
                                          What is Competency Framework?
                                        </button>
                                      </h2>
                                      <div id="cat1ques1" class="accordion-collapse collapse show" aria-labelledby="cat1ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat1ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques2" aria-expanded="false" aria-controls="cat1ques2">Which Assessment do I need to complete First? Competency Assessment or Personality Assessment.
                                        </button>
                                      </h2>
                                      <div id="cat1ques2" class="accordion-collapse collapse" aria-labelledby="cat1ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat1ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques3" aria-expanded="false" aria-controls="cat1ques3">
                                          I'm new to the Competency Framework. How do I create an Account?
                                        </button>
                                      </h2>
                                      <div id="cat1ques3" class="accordion-collapse collapse" aria-labelledby="cat1ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat1ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques4" aria-expanded="false" aria-controls="cat1ques4">
                                            How do I Login into my Account for Competency Framework?
                                          </button>
                                        </h2>
                                        <div id="cat1ques4" class="accordion-collapse collapse" aria-labelledby="cat1ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div> -->
                            <!-- <div class="tab-pane fade" id="general2" role="tabpanel" aria-labelledby="general-tab2">
                                <div class="title_block float-start">
                                    <span class="content"><h2>User Administration</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab2" (click)="show_answers(2)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab2" (click)="hide_answers(2)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab2">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques1" aria-expanded="true" aria-controls="cat2ques1">
                                          Do I need to buy License to take Competency Framework Assessment?
                                        </button>
                                      </h2>
                                      <div id="cat2ques1" class="accordion-collapse collapse show" aria-labelledby="cat2ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques2" aria-expanded="false" aria-controls="cat2ques2">
                                          My Organization is already registered with the AMSN. Still do I need to buy license for take Competency Framework Assessment?
                                        </button>
                                      </h2>
                                      <div id="cat2ques2" class="accordion-collapse collapse" aria-labelledby="cat2ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques3" aria-expanded="false" aria-controls="cat2ques3">
                                          How do I buy license for myself?
                                        </button>
                                      </h2>
                                      <div id="cat2ques3" class="accordion-collapse collapse" aria-labelledby="cat2ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </div> -->
                            <!-- <div class="tab-pane fade" id="general3" role="tabpanel" aria-labelledby="general-tab3">
                                <div class="title_block float-start">
                                    <span class="content"><h2>Organization Administration</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab3" (click)="show_answers(3)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab3" (click)="hide_answers(3)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab3">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat3ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques1" aria-expanded="true" aria-controls="cat3ques1">
                                            What are the function of the Connector?	    
                                        </button>
                                      </h2>
                                      <div id="cat3ques1" class="accordion-collapse collapse show" aria-labelledby="cat3ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat3ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques2" aria-expanded="false" aria-controls="cat3ques2">
                                            How do I register an organization?
                                        </button>
                                      </h2>
                                      <div id="cat3ques2" class="accordion-collapse collapse" aria-labelledby="cat3ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat3ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques3" aria-expanded="false" aria-controls="cat3ques3">
                                            Can I register more than one organization?
                                        </button>
                                      </h2>
                                      <div id="cat3ques3" class="accordion-collapse collapse" aria-labelledby="cat3ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques4" aria-expanded="false" aria-controls="cat3ques4">
                                            How do I change the Role, Division or Unit of a User?
                                          </button>
                                        </h2>
                                        <div id="cat3ques4" class="accordion-collapse collapse" aria-labelledby="cat3ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques5header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques5" aria-expanded="false" aria-controls="cat3ques5">
                                            Are there any limits in connecting Users and creating Divisions and Units?
                                          </button>
                                        </h2>
                                        <div id="cat3ques5" class="accordion-collapse collapse" aria-labelledby="cat3ques5header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques6header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques6" aria-expanded="false" aria-controls="cat3ques6">
                                            How do I change the Role of multiple Users at once?
                                          </button>
                                        </h2>
                                        <div id="cat3ques6" class="accordion-collapse collapse" aria-labelledby="cat3ques6header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques7header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques7" aria-expanded="false" aria-controls="cat3ques7">
                                            How do I change the Division and Unit of multiple Users at once?
                                          </button>
                                        </h2>
                                        <div id="cat3ques7" class="accordion-collapse collapse" aria-labelledby="cat3ques7header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques8header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques8" aria-expanded="false" aria-controls="cat3ques8">
                                            I am Primary Contact/Administrator to more than one Organization. How do I switch from one Organization to another?
                                          </button>
                                        </h2>
                                        <div id="cat3ques8" class="accordion-collapse collapse" aria-labelledby="cat3ques8header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques9header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques9" aria-expanded="false" aria-controls="cat3ques9">
                                            How do I update Organization details?
                                          </button>
                                        </h2>
                                        <div id="cat3ques9" class="accordion-collapse collapse" aria-labelledby="cat3ques9header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques10header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques10" aria-expanded="false" aria-controls="cat3ques10">
                                            Can an Organization have multiple Primary Contacts?
                                          </button>
                                        </h2>
                                        <div id="cat3ques10" class="accordion-collapse collapse" aria-labelledby="cat3ques10header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques11header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques11" aria-expanded="false" aria-controls="cat3ques11">
                                            How do I change the Primary Contact for an Organization?
                                          </button>
                                        </h2>
                                        <div id="cat3ques11" class="accordion-collapse collapse" aria-labelledby="cat3ques11header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div> -->
                            <!-- <div class="tab-pane fade" id="general4" role="tabpanel" aria-labelledby="general-tab4">
                                <div class="title_block float-start">
                                    <span class="content"><h2>DCM Administration</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab4" (click)="show_answers(4)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab4" (click)="hide_answers(4)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab4">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat4ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques1" aria-expanded="true" aria-controls="cat4ques1">
                                            What are the usages for Digital Competency Model Administration?
                                        </button>
                                      </h2>
                                      <div id="cat4ques1" class="accordion-collapse collapse show" aria-labelledby="cat4ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat4ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques2" aria-expanded="false" aria-controls="cat4ques2">
                                            Can a User request for an extension?
                                        </button>
                                      </h2>
                                      <div id="cat4ques2" class="accordion-collapse collapse" aria-labelledby="cat4ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat4ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques3" aria-expanded="false" aria-controls="cat4ques3">
                                            After the extension is applied, how much time User will get to complete the Assessment?
                                        </button>
                                      </h2>
                                      <div id="cat4ques3" class="accordion-collapse collapse" aria-labelledby="cat4ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques4" aria-expanded="false" aria-controls="cat4ques4">
                                            After the license expiry, how long is User eligible to apply for an extension?
                                          </button>
                                        </h2>
                                        <div id="cat4ques4" class="accordion-collapse collapse" aria-labelledby="cat4ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques5header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques5" aria-expanded="false" aria-controls="cat4ques5">
                                            How many times a User can apply for an extension?
                                          </button>
                                        </h2>
                                        <div id="cat4ques5" class="accordion-collapse collapse" aria-labelledby="cat4ques5header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques6header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques6" aria-expanded="false" aria-controls="cat4ques6">
                                            How do I see Results of a completed Digital Competency Model Assessment?
                                          </button>
                                        </h2>
                                        <div id="cat4ques6" class="accordion-collapse collapse" aria-labelledby="cat4ques6header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques7header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques7" aria-expanded="false" aria-controls="cat4ques7">
                                            How do I see the Assessments statistics for Division level?
                                          </button>
                                        </h2>
                                        <div id="cat4ques7" class="accordion-collapse collapse" aria-labelledby="cat4ques7header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques8header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques8" aria-expanded="false" aria-controls="cat4ques8">
                                            Can I export the Division Overview data into Excel format?
                                          </button>
                                        </h2>
                                        <div id="cat4ques8" class="accordion-collapse collapse" aria-labelledby="cat4ques8header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques9header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques9" aria-expanded="false" aria-controls="cat4ques9">
                                            As a Primary Contact and/or Administrator, how can I start my Assessment?
                                          </button>
                                        </h2>
                                        <div id="cat4ques9" class="accordion-collapse collapse" aria-labelledby="cat4ques9header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div> -->
                            <!-- <div class="tab-pane fade" id="general5" role="tabpanel" aria-labelledby="general-tab5">
                                <div class="title_block float-start">
                                    <span class="content"><h2>Licenses</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab5" (click)="show_answers(5)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab5" (click)="hide_answers(5)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab5">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques1" aria-expanded="true" aria-controls="cat5ques1">
                                            What are the usages for Digital Competency Model Administration?
                                        </button>
                                      </h2>
                                      <div id="cat5ques1" class="accordion-collapse collapse show" aria-labelledby="cat5ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques2" aria-expanded="false" aria-controls="cat5ques2">
                                            How do I buy the licenses for my organization?
                                        </button>
                                      </h2>
                                      <div id="cat5ques2" class="accordion-collapse collapse" aria-labelledby="cat5ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques3" aria-expanded="false" aria-controls="cat5ques3">
                                            How can I get a copy of an invoice for licenses purchased?
                                        </button>
                                      </h2>
                                      <div id="cat5ques3" class="accordion-collapse collapse" aria-labelledby="cat5ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques4" aria-expanded="false" aria-controls="cat5ques4">
                                            How can I get a copy of the email confirmation for licenses purchased?
                                          </button>
                                        </h2>
                                        <div id="cat5ques4" class="accordion-collapse collapse" aria-labelledby="cat5ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques5header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques5" aria-expanded="false" aria-controls="cat5ques5">
                                            How do I assign the licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques5" class="accordion-collapse collapse" aria-labelledby="cat5ques5header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques6header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques6" aria-expanded="false" aria-controls="cat5ques6">
                                            How can I buy additional licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques6" class="accordion-collapse collapse" aria-labelledby="cat5ques6header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques7header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques7" aria-expanded="false" aria-controls="cat5ques7">
                                            I bought licenses more than required. How do I cancel and get a refund?	
                                          </button>
                                        </h2>
                                        <div id="cat5ques7" class="accordion-collapse collapse" aria-labelledby="cat5ques7header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques8header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques8" aria-expanded="false" aria-controls="cat5ques8">
                                            How can I revoke a license from the User?
                                          </button>
                                        </h2>
                                        <div id="cat5ques8" class="accordion-collapse collapse" aria-labelledby="cat5ques8header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques9header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques9" aria-expanded="false" aria-controls="cat5ques9">
                                            I want to revoke a license for a User. Why could I not find the revoke option?
                                          </button>
                                        </h2>
                                        <div id="cat5ques9" class="accordion-collapse collapse" aria-labelledby="cat5ques9header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques10header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques10" aria-expanded="false" aria-controls="cat5ques10">
                                            A license has been assigned to a User, but the User didn’t receive the email. Can I resend the email?
                                          </button>
                                        </h2>
                                        <div id="cat5ques10" class="accordion-collapse collapse" aria-labelledby="cat5ques10header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques11header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques11" aria-expanded="false" aria-controls="cat5ques11">
                                            I want to Re-assign/transfer a license to a different User. Why could I not find the Re-assign/transfer option?
                                          </button>
                                        </h2>
                                        <div id="cat5ques11" class="accordion-collapse collapse" aria-labelledby="cat5ques11header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques12header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques12" aria-expanded="false" aria-controls="cat5ques12">
                                            Can I renew my licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques12" class="accordion-collapse collapse" aria-labelledby="cat5ques12header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques13header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques13" aria-expanded="false" aria-controls="cat5ques13">
                                            While assigning the Licenses to Users, I could not find the User. How do I add the User-to List?	
                                          </button>
                                        </h2>
                                        <div id="cat5ques13" class="accordion-collapse collapse" aria-labelledby="cat5ques13header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="tab-pane fade" id="Administrators" role="tabpanel" aria-labelledby="Administrators-tab">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-2 pe-0 ps-0">
                    <div class="faq_tabs">
                      <ul class="nav nav-tabs" id="faqTab" role="tablist">
                        <ng-container *ngFor="let category of searchAadministratorsQueAnsArray;let i=index;">
                          <li class="nav-item" role="presentation" *ngIf="category.child.length > 0">
                            <button class="nav-link active" [ngClass]="{active:i==0}" id="administrators-tab{{i+1}}" data-bs-toggle="tab" [attr.data-bs-target]="'#administrators'+(i+1)" type="button" role="tab" [attr.aria-controls]="'administrators'+(i+1)" aria-selected="true">
                                <span class="content"><h2>{{category.subCategory}} <span *ngIf="statement_show">({{category.child.length}})</span></h2></span>
                                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                            </button>
                          </li>
                        </ng-container>
                        
                      </ul>
                        <!-- <ul class="nav nav-tabs" id="faqTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="administrators-tab1" data-bs-toggle="tab" data-bs-target="#administrators1" type="button" role="tab" aria-controls="administrators1" aria-selected="true">
                                    <span class="content"><h2>Getting Started</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="administrators-tab2" data-bs-toggle="tab" data-bs-target="#administrators2" type="button" role="tab" aria-controls="administrators2" aria-selected="false">
                                    <span class="content"><h2>Your Organization</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="administrators-tab3" data-bs-toggle="tab" data-bs-target="#administrators3" type="button" role="tab" aria-controls="administrators3" aria-selected="false">
                                    <span class="content"><h2>User Management</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="administrators-tab4" data-bs-toggle="tab" data-bs-target="#administrators4" type="button" role="tab" aria-controls="administrators4" aria-selected="false">
                                    <span class="content"><h2>All about License</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="administrators-tab5" data-bs-toggle="tab" data-bs-target="#administrators5" type="button" role="tab" aria-controls="administrators5" aria-selected="false">
                                    <span class="content"><h2>About Extension</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="administrators-tab6" data-bs-toggle="tab" data-bs-target="#administrators6" type="button" role="tab" aria-controls="administrators6" aria-selected="false">
                                    <span class="content"><h2>Manage Assessment</h2></span>
                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-10 ps-0 pe-0">
                    <div class="faq_content">
                        <div class="tab-content" id="faqTabContent">
                          <ng-container *ngFor="let administratorsquestions of searchAadministratorsQueAnsArray;let a = index;">
                            <div class="tab-pane fade " id="administrators{{a+1}}" role="tabpanel" [attr.aria-labelledby]="'administrators-tab'+(a+1)"
                              [ngClass]="{show:a==0,active:a==0}">
                              <div class="title_block float-start">
                                  <span class="content"><h2>{{administratorsquestions.subCategory}}</h2></span>
                              </div>
                              <button class="all_btn theme_btn float-end" id="showanswer_tab1" (click)="show_answers(1)">Show all answers</button>
                              <button class="all_btn theme_btn float-end" id="hideanswer_tab1" (click)="hide_answers(1)" style="display: none;">Hide all answers</button>
                              <div class="clearfix"></div>
                              <div class="accordion" id="question_tab{{a+1}}" >
                                  <div class="accordion-item" *ngFor="let queans of administratorsquestions.child;let b=index;">
                                    <h2 class="accordion-header" id="cat1quesheader{{a}}{{b}}">
                                      <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#cat1ques'+a+b" aria-expanded="true" [attr.aria-controls]="'cat1ques'+a+b">
                                        {{queans.question}}
                                      </button>
                                    </h2>
                                    <div id="cat1ques{{a}}{{b}}" class="accordion-collapse collapse " [ngClass]="{show:b==0}" [attr.aria-labelledby]="'cat1quesheader'+a+b">
                                      <div class="accordion-body">
                                        <span [innerHTML] = "queans.answer"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </ng-container>
                            <!-- <div class="tab-pane fade show active" id="administrators1" role="tabpanel" aria-labelledby="administrators-tab1">
                                <div class="title_block float-start">
                                    <span class="content"><h2>Getting Started</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab1" (click)="show_answers(1)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab1" (click)="hide_answers(1)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab1">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat1ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques1" aria-expanded="true" aria-controls="cat1ques1">
                                          What is Competency Framework?
                                        </button>
                                      </h2>
                                      <div id="cat1ques1" class="accordion-collapse collapse show" aria-labelledby="cat1ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat1ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques2" aria-expanded="false" aria-controls="cat1ques2">Which Assessment do I need to complete First? Competency Assessment or Personality Assessment.
                                        </button>
                                      </h2>
                                      <div id="cat1ques2" class="accordion-collapse collapse" aria-labelledby="cat1ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat1ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques3" aria-expanded="false" aria-controls="cat1ques3">
                                          I'm new to the Competency Framework. How do I create an Account?
                                        </button>
                                      </h2>
                                      <div id="cat1ques3" class="accordion-collapse collapse" aria-labelledby="cat1ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat1ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques4" aria-expanded="false" aria-controls="cat1ques4">
                                            How do I Login into my Account for Competency Framework?
                                          </button>
                                        </h2>
                                        <div id="cat1ques4" class="accordion-collapse collapse" aria-labelledby="cat1ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div>
                            <div class="tab-pane fade" id="administrators2" role="tabpanel" aria-labelledby="administrators-tab2">
                                <div class="title_block float-start">
                                    <span class="content"><h2>User Administration</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab2" (click)="show_answers(2)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab2" (click)="hide_answers(2)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab2">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques1" aria-expanded="true" aria-controls="cat2ques1">
                                          Do I need to buy License to take Competency Framework Assessment?
                                        </button>
                                      </h2>
                                      <div id="cat2ques1" class="accordion-collapse collapse show" aria-labelledby="cat2ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques2" aria-expanded="false" aria-controls="cat2ques2">
                                          My Organization is already registered with the AMSN. Still do I need to buy license for take Competency Framework Assessment?
                                        </button>
                                      </h2>
                                      <div id="cat2ques2" class="accordion-collapse collapse" aria-labelledby="cat2ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques3" aria-expanded="false" aria-controls="cat2ques3">
                                          How do I buy license for myself?
                                        </button>
                                      </h2>
                                      <div id="cat2ques3" class="accordion-collapse collapse" aria-labelledby="cat2ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                            <div class="tab-pane fade" id="administrators3" role="tabpanel" aria-labelledby="administrators-tab3">
                                <div class="title_block float-start">
                                    <span class="content"><h2>Organization Administration</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab3" (click)="show_answers(3)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab3" (click)="hide_answers(3)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab3">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat3ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques1" aria-expanded="true" aria-controls="cat3ques1">
                                            What are the function of the Connector?	    
                                        </button>
                                      </h2>
                                      <div id="cat3ques1" class="accordion-collapse collapse show" aria-labelledby="cat3ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat3ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques2" aria-expanded="false" aria-controls="cat3ques2">
                                            How do I register an organization?
                                        </button>
                                      </h2>
                                      <div id="cat3ques2" class="accordion-collapse collapse" aria-labelledby="cat3ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat3ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques3" aria-expanded="false" aria-controls="cat3ques3">
                                            Can I register more than one organization?
                                        </button>
                                      </h2>
                                      <div id="cat3ques3" class="accordion-collapse collapse" aria-labelledby="cat3ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques4" aria-expanded="false" aria-controls="cat3ques4">
                                            How do I change the Role, Division or Unit of a User?
                                          </button>
                                        </h2>
                                        <div id="cat3ques4" class="accordion-collapse collapse" aria-labelledby="cat3ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques5header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques5" aria-expanded="false" aria-controls="cat3ques5">
                                            Are there any limits in connecting Users and creating Divisions and Units?
                                          </button>
                                        </h2>
                                        <div id="cat3ques5" class="accordion-collapse collapse" aria-labelledby="cat3ques5header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques6header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques6" aria-expanded="false" aria-controls="cat3ques6">
                                            How do I change the Role of multiple Users at once?
                                          </button>
                                        </h2>
                                        <div id="cat3ques6" class="accordion-collapse collapse" aria-labelledby="cat3ques6header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques7header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques7" aria-expanded="false" aria-controls="cat3ques7">
                                            How do I change the Division and Unit of multiple Users at once?
                                          </button>
                                        </h2>
                                        <div id="cat3ques7" class="accordion-collapse collapse" aria-labelledby="cat3ques7header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques8header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques8" aria-expanded="false" aria-controls="cat3ques8">
                                            I am Primary Contact/Administrator to more than one Organization. How do I switch from one Organization to another?
                                          </button>
                                        </h2>
                                        <div id="cat3ques8" class="accordion-collapse collapse" aria-labelledby="cat3ques8header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques9header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques9" aria-expanded="false" aria-controls="cat3ques9">
                                            How do I update Organization details?
                                          </button>
                                        </h2>
                                        <div id="cat3ques9" class="accordion-collapse collapse" aria-labelledby="cat3ques9header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques10header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques10" aria-expanded="false" aria-controls="cat3ques10">
                                            Can an Organization have multiple Primary Contacts?
                                          </button>
                                        </h2>
                                        <div id="cat3ques10" class="accordion-collapse collapse" aria-labelledby="cat3ques10header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat3ques11header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat3ques11" aria-expanded="false" aria-controls="cat3ques11">
                                            How do I change the Primary Contact for an Organization?
                                          </button>
                                        </h2>
                                        <div id="cat3ques11" class="accordion-collapse collapse" aria-labelledby="cat3ques11header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div>
                            <div class="tab-pane fade" id="administrators4" role="tabpanel" aria-labelledby="administrators-tab4">
                                <div class="title_block float-start">
                                    <span class="content"><h2>DCM Administration</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab4" (click)="show_answers(4)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab4" (click)="hide_answers(4)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab4">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat4ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques1" aria-expanded="true" aria-controls="cat4ques1">
                                            What are the usages for Digital Competency Model Administration?
                                        </button>
                                      </h2>
                                      <div id="cat4ques1" class="accordion-collapse collapse show" aria-labelledby="cat4ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat4ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques2" aria-expanded="false" aria-controls="cat4ques2">
                                            Can a User request for an extension?
                                        </button>
                                      </h2>
                                      <div id="cat4ques2" class="accordion-collapse collapse" aria-labelledby="cat4ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat4ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques3" aria-expanded="false" aria-controls="cat4ques3">
                                            After the extension is applied, how much time User will get to complete the Assessment?
                                        </button>
                                      </h2>
                                      <div id="cat4ques3" class="accordion-collapse collapse" aria-labelledby="cat4ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques4" aria-expanded="false" aria-controls="cat4ques4">
                                            After the license expiry, how long is User eligible to apply for an extension?
                                          </button>
                                        </h2>
                                        <div id="cat4ques4" class="accordion-collapse collapse" aria-labelledby="cat4ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques5header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques5" aria-expanded="false" aria-controls="cat4ques5">
                                            How many times a User can apply for an extension?
                                          </button>
                                        </h2>
                                        <div id="cat4ques5" class="accordion-collapse collapse" aria-labelledby="cat4ques5header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques6header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques6" aria-expanded="false" aria-controls="cat4ques6">
                                            How do I see Results of a completed Digital Competency Model Assessment?
                                          </button>
                                        </h2>
                                        <div id="cat4ques6" class="accordion-collapse collapse" aria-labelledby="cat4ques6header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques7header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques7" aria-expanded="false" aria-controls="cat4ques7">
                                            How do I see the Assessments statistics for Division level?
                                          </button>
                                        </h2>
                                        <div id="cat4ques7" class="accordion-collapse collapse" aria-labelledby="cat4ques7header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques8header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques8" aria-expanded="false" aria-controls="cat4ques8">
                                            Can I export the Division Overview data into Excel format?
                                          </button>
                                        </h2>
                                        <div id="cat4ques8" class="accordion-collapse collapse" aria-labelledby="cat4ques8header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat4ques9header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat4ques9" aria-expanded="false" aria-controls="cat4ques9">
                                            As a Primary Contact and/or Administrator, how can I start my Assessment?
                                          </button>
                                        </h2>
                                        <div id="cat4ques9" class="accordion-collapse collapse" aria-labelledby="cat4ques9header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div>
                            <div class="tab-pane fade" id="administrators5" role="tabpanel" aria-labelledby="administrators-tab5">
                                <div class="title_block float-start">
                                    <span class="content"><h2>Licenses</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab5" (click)="show_answers(5)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab5" (click)="hide_answers(5)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab5">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques1" aria-expanded="true" aria-controls="cat5ques1">
                                            What are the usages for Digital Competency Model Administration?
                                        </button>
                                      </h2>
                                      <div id="cat5ques1" class="accordion-collapse collapse show" aria-labelledby="cat5ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques2" aria-expanded="false" aria-controls="cat5ques2">
                                            How do I buy the licenses for my organization?
                                        </button>
                                      </h2>
                                      <div id="cat5ques2" class="accordion-collapse collapse" aria-labelledby="cat5ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques3" aria-expanded="false" aria-controls="cat5ques3">
                                            How can I get a copy of an invoice for licenses purchased?
                                        </button>
                                      </h2>
                                      <div id="cat5ques3" class="accordion-collapse collapse" aria-labelledby="cat5ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques4" aria-expanded="false" aria-controls="cat5ques4">
                                            How can I get a copy of the email confirmation for licenses purchased?
                                          </button>
                                        </h2>
                                        <div id="cat5ques4" class="accordion-collapse collapse" aria-labelledby="cat5ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques5header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques5" aria-expanded="false" aria-controls="cat5ques5">
                                            How do I assign the licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques5" class="accordion-collapse collapse" aria-labelledby="cat5ques5header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques6header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques6" aria-expanded="false" aria-controls="cat5ques6">
                                            How can I buy additional licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques6" class="accordion-collapse collapse" aria-labelledby="cat5ques6header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques7header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques7" aria-expanded="false" aria-controls="cat5ques7">
                                            I bought licenses more than required. How do I cancel and get a refund?	
                                          </button>
                                        </h2>
                                        <div id="cat5ques7" class="accordion-collapse collapse" aria-labelledby="cat5ques7header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques8header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques8" aria-expanded="false" aria-controls="cat5ques8">
                                            How can I revoke a license from the User?
                                          </button>
                                        </h2>
                                        <div id="cat5ques8" class="accordion-collapse collapse" aria-labelledby="cat5ques8header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques9header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques9" aria-expanded="false" aria-controls="cat5ques9">
                                            I want to revoke a license for a User. Why could I not find the revoke option?
                                          </button>
                                        </h2>
                                        <div id="cat5ques9" class="accordion-collapse collapse" aria-labelledby="cat5ques9header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques10header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques10" aria-expanded="false" aria-controls="cat5ques10">
                                            A license has been assigned to a User, but the User didn’t receive the email. Can I resend the email?
                                          </button>
                                        </h2>
                                        <div id="cat5ques10" class="accordion-collapse collapse" aria-labelledby="cat5ques10header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques11header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques11" aria-expanded="false" aria-controls="cat5ques11">
                                            I want to Re-assign/transfer a license to a different User. Why could I not find the Re-assign/transfer option?
                                          </button>
                                        </h2>
                                        <div id="cat5ques11" class="accordion-collapse collapse" aria-labelledby="cat5ques11header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques12header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques12" aria-expanded="false" aria-controls="cat5ques12">
                                            Can I renew my licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques12" class="accordion-collapse collapse" aria-labelledby="cat5ques12header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques13header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques13" aria-expanded="false" aria-controls="cat5ques13">
                                            While assigning the Licenses to Users, I could not find the User. How do I add the User-to List?	
                                          </button>
                                        </h2>
                                        <div id="cat5ques13" class="accordion-collapse collapse" aria-labelledby="cat5ques13header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div>
                            <div class="tab-pane fade" id="administrators6" role="tabpanel" aria-labelledby="administrators-tab6">
                                <div class="title_block float-start">
                                    <span class="content"><h2>Licenses</h2></span>
                                </div>
                                <button class="all_btn theme_btn float-end" id="showanswer_tab5" (click)="show_answers(5)">Show all answers</button>
                                <button class="all_btn theme_btn float-end" id="hideanswer_tab5" (click)="hide_answers(5)" style="display: none;">Hide all answers</button>
                                <div class="clearfix"></div>
                                <div class="accordion" id="question_tab5">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques1header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques1" aria-expanded="true" aria-controls="cat5ques1">
                                            What are the usages for Digital Competency Model Administration?
                                        </button>
                                      </h2>
                                      <div id="cat5ques1" class="accordion-collapse collapse show" aria-labelledby="cat5ques1header">
                                        <div class="accordion-body">
                                          This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques2header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques2" aria-expanded="false" aria-controls="cat5ques2">
                                            How do I buy the licenses for my organization?
                                        </button>
                                      </h2>
                                      <div id="cat5ques2" class="accordion-collapse collapse" aria-labelledby="cat5ques2header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat5ques3header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques3" aria-expanded="false" aria-controls="cat5ques3">
                                            How can I get a copy of an invoice for licenses purchased?
                                        </button>
                                      </h2>
                                      <div id="cat5ques3" class="accordion-collapse collapse" aria-labelledby="cat5ques3header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques4header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques4" aria-expanded="false" aria-controls="cat5ques4">
                                            How can I get a copy of the email confirmation for licenses purchased?
                                          </button>
                                        </h2>
                                        <div id="cat5ques4" class="accordion-collapse collapse" aria-labelledby="cat5ques4header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques5header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques5" aria-expanded="false" aria-controls="cat5ques5">
                                            How do I assign the licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques5" class="accordion-collapse collapse" aria-labelledby="cat5ques5header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques6header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques6" aria-expanded="false" aria-controls="cat5ques6">
                                            How can I buy additional licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques6" class="accordion-collapse collapse" aria-labelledby="cat5ques6header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques7header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques7" aria-expanded="false" aria-controls="cat5ques7">
                                            I bought licenses more than required. How do I cancel and get a refund?	
                                          </button>
                                        </h2>
                                        <div id="cat5ques7" class="accordion-collapse collapse" aria-labelledby="cat5ques7header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques8header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques8" aria-expanded="false" aria-controls="cat5ques8">
                                            How can I revoke a license from the User?
                                          </button>
                                        </h2>
                                        <div id="cat5ques8" class="accordion-collapse collapse" aria-labelledby="cat5ques8header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques9header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques9" aria-expanded="false" aria-controls="cat5ques9">
                                            I want to revoke a license for a User. Why could I not find the revoke option?
                                          </button>
                                        </h2>
                                        <div id="cat5ques9" class="accordion-collapse collapse" aria-labelledby="cat5ques9header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques10header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques10" aria-expanded="false" aria-controls="cat5ques10">
                                            A license has been assigned to a User, but the User didn’t receive the email. Can I resend the email?
                                          </button>
                                        </h2>
                                        <div id="cat5ques10" class="accordion-collapse collapse" aria-labelledby="cat5ques10header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques11header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques11" aria-expanded="false" aria-controls="cat5ques11">
                                            I want to Re-assign/transfer a license to a different User. Why could I not find the Re-assign/transfer option?
                                          </button>
                                        </h2>
                                        <div id="cat5ques11" class="accordion-collapse collapse" aria-labelledby="cat5ques11header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques12header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques12" aria-expanded="false" aria-controls="cat5ques12">
                                            Can I renew my licenses?
                                          </button>
                                        </h2>
                                        <div id="cat5ques12" class="accordion-collapse collapse" aria-labelledby="cat5ques12header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="cat5ques13header">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat5ques13" aria-expanded="false" aria-controls="cat5ques13">
                                            While assigning the Licenses to Users, I could not find the User. How do I add the User-to List?	
                                          </button>
                                        </h2>
                                        <div id="cat5ques13" class="accordion-collapse collapse" aria-labelledby="cat5ques13header">
                                          <div class="accordion-body">
                                              This is the placeholder for answer
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          </div>
          <div class="no_found_block" >
            <img src="../assets/images/no_found.png" alt="" />
            <h3>No Questions found</h3>
            <p>Try adjusting your search to find what you're looking for.</p>
            <button class="clear_search_btn" (click)="clearSearch()"><i class="fa fa-times" aria-hidden="true"></i>Clear search</button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
</div>