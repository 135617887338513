import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  body:any;
  constructor() { }

  ngOnInit(): void {
    this.body = document.querySelector('body');
    this.body.style.setProperty('--screen-height', $(window).height() + "px");
  }

}
